<template>
  <!-- 运营外部班级 -->
  <div class="pageContol listWrap outClassList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="dohandleOk">班级显示设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
          >查看日志</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <span style="padding: 0 0.675rem;font-weight: 700;">查看日志</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                height="100%"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  width="100px"
              />
              <el-table-column
                  label="操作内容"
                  align="left"
                  prop="opDesc"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="操作人"
                  align="left"
                  prop="opRealname"
                  show-overflow-tooltip
              />
              <el-table-column
                  label="操作日期"
                  align="left"
                  show-overflow-tooltip
                  prop="createTime"
              />
<!--              <el-table-column-->
<!--                  label="备注"-->
<!--                  align="left"-->
<!--                  show-overflow-tooltip-->
<!--                  prop="remark"-->
<!--              />-->

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
<!--        <PageNum-->
<!--            :apiData="apiData"-->
<!--            @sizeChange="sizeChange"-->
<!--            @getData="getData"-->
<!--        />-->
      </div>

    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "classDisplaySetLogs",
  components: {
    Empty,
    // PageNum,
  },
  mixins: [List],
  data() {
    return {
      // startTime: "",
      // endTime: "",
      // classstatus: "",
      // ClassstatusList: [],
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {

  },
  methods: {
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/classDisplaySetList",
          query: {
            refresh: true
          }
        });
      }, 300);
    },
    // 获取列表数据
    getData() {
      const params = {
        // pageNum: pageNum,
        // pageSize: this.pageSize,
        projectId: this.$route.query.projectId,
      };
      this.doFetch({
        url: "/operationlog/accountingLog",
        params,
        // pageNum,
      });
    },
  },
  watch: {

  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
/deep/.el-switch__label--left.is-active + .el-switch__core{
  border-color: rgb(255, 73, 73)!important;
  background-color: rgb(255, 73, 73)!important;
}
/deep/.el-switch.is-checked .el-switch__core {
  background-color: #5fb878 !important;
  border-color: #5fb878 !important;
}
/deep/ .el-switch__label--left{
  left: 0!important;
  z-index: 999!important;
}
/deep/ .el-switch__label--right{
  right: 0!important;
  z-index: 999!important;
}
/deep/ .el-switch__label{
  color: #666!important;
}
/deep/ .el-switch__label.is-active {
  color: #409eff!important;
}
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>